import { universalDark } from './universalDark';
import { universalLight } from './universalLight';

// OP: japanese rising sun
// Base: plastiwrapped blue orbs
// Mode: lightning bolt

// these are the same regardless of light vs. dark mode
const commonColors = {
  white: '#fff',
  black: '#000',
  red: '#FF0000',
  red911: '#911',
  neongreen: '#60FE00',
  candleGreen: '#52b555',
  softgreen: '#00F89C',
  darker: '#bbb',
  warning: '#ea4',
  offwhite: '#FBFAF9',
  lightwhite: '#fffff0',
  tan: '#EFEDE6',
  pearl: '#f5f5f0',
  porcelain: '#EFEDE6',

  orange: 'rgba(234, 88, 12, 1)',

  pearlAlpha: {
    100: 'rgba(245, 245, 240, 0.1)',
    200: 'rgba(245, 245, 240, 0.2)',
    300: 'rgba(245, 245, 240, 0.3)',
    400: 'rgba(245, 245, 240, 0.4)',
    500: 'rgba(245, 245, 240, 0.5)',
    600: 'rgba(245, 245, 240, 0.6)',
    700: 'rgba(245, 245, 240, 0.7)',
    800: 'rgba(245, 245, 240, 0.8)',
    900: 'rgba(245, 245, 240, 0.9)',
  },
  porcelainAlpha: {
    100: 'rgba(239, 237, 230, 0.1)',
    150: 'rgba(200, 200, 200, 0.2)',
    200: 'rgba(239, 237, 230, 0.2)',
    300: 'rgba(239, 237, 230, 0.3)',
    400: 'rgba(239, 237, 230, 0.4)',
    500: 'rgba(239, 237, 230, 0.5)',
    600: 'rgba(239, 237, 230, 0.6)',
    700: 'rgba(239, 237, 230, 0.7)',
    800: 'rgba(239, 237, 230, 0.8)',
    900: 'rgba(239, 237, 230, 0.9)',
  },

  greenAlpha: {
    '50': 'rgba(0,248,156, 0.05)',
    '100': 'rgba(0,248,156, 0.1)',
    '200': 'rgba(0,248,156, 0.2)',
    '300': 'rgba(0,248,156, 0.3)',
    '400': 'rgba(0,248,156, 0.4)',
    '500': 'rgba(0,248,156, 0.5)',
    '600': 'rgba(0,248,156, 0.6)',
    '700': 'rgba(0,248,156, 0.7)',
    '800': 'rgba(0,248,156, 0.8)',
    '900': 'rgba(0,248,156, 0.9)',
  },

  blackScale: {
    '50': 'rgba(64, 64, 64, 1)',
    '100': 'rgba(56, 56, 56, 1)',
    '200': 'rgba(48, 48, 48, 1)',
    '300': 'rgba(40, 40, 40, 1)',
    '400': 'rgba(32, 32, 32, 1)',
    '500': 'rgba(24, 24, 24, 1)',
    '600': 'rgba(16, 16, 16, 1)',
    '700': 'rgba(8, 8, 8, 1)',
    '800': 'rgba(4, 4, 4, 1)',
    '900': 'rgba(0, 0, 0, 1)',
  },

  pureBlackAlpha: {
    '50': 'rgba(0, 0, 0, 0.05)',
    '100': 'rgba(0, 0, 0, 0.1)',
    '200': 'rgba(0, 0, 0, 0.2)',
    '300': 'rgba(0, 0, 0, 0.3)',
    '400': 'rgba(0, 0, 0, 0.4)',
    '500': 'rgba(0, 0, 0, 0.5)',
    '600': 'rgba(0, 0, 0, 0.6)',
    '700': 'rgba(0, 0, 0, 0.7)',
    '800': 'rgba(0, 0, 0, 0.8)',
    '900': 'rgba(0, 0, 0, 0.9)',
  },
  highlightAlpha: {
    '50': 'rgba(0, 255, 255, 0.05)',
    '100': 'rgba(0, 255, 255, 0.1)',
    '200': 'rgba(0, 255, 255, 0.2)',
    '300': 'rgba(0, 255, 255, 0.3)',
    '400': 'rgba(0, 255, 255, 0.4)',
    '500': 'rgba(0, 255, 255, 0.5)',
    '600': 'rgba(0, 255, 255, 0.6)',
    '700': 'rgba(0, 255, 255, 0.7)',
    '800': 'rgba(0, 255, 255, 0.8)',
    '900': 'rgba(0, 255, 255, 0.9)',
  },
  grayAlpha: {
    '100': 'rgba(80,80,80, 0.1)',
    '200': 'rgba(80,80,80, 0.2)',
    '300': 'rgba(80,80,80, 0.3)',
    '400': 'rgba(80,80,80, 0.4)',
    '500': 'rgba(80,80,80, 0.5)',
    '600': 'rgba(80,80,80, 0.6)',
    '700': 'rgba(80,80,80, 0.7)',
    '800': 'rgba(80,80,80, 0.8)',
    '900': 'rgba(80,80,80, 0.9)',
  },
  purpleAlpha: {
    '50': 'rgba(147, 51, 234, 0.05)',
    '100': 'rgba(147, 51, 234, 0.1)',
    '200': 'rgba(147, 51, 234, 0.2)',
    '300': 'rgba(147, 51, 234, 0.3)',
    '400': 'rgba(147, 51, 234, 0.4)',
    '500': 'rgba(147, 51, 234, 0.5)',
    '600': 'rgba(147, 51, 234, 0.6)',
    '700': 'rgba(147, 51, 234, 0.7)',
    '800': 'rgba(147, 51, 234, 0.8)',
    '900': 'rgba(147, 51, 234, 0.9)',
  },
  orangeAlpha: {
    '50': 'rgba(234, 88, 12, 0.05)',
    '100': 'rgba(234, 88, 12, 0.1)',
    '200': 'rgba(234, 88, 12, 0.2)',
    '300': 'rgba(234, 88, 12, 0.3)',
    '400': 'rgba(234, 88, 12, 0.4)',
    '500': 'rgba(234, 88, 12, 0.5)',
    '600': 'rgba(234, 88, 12, 0.6)',
    '700': 'rgba(234, 88, 12, 0.7)',
    '800': 'rgba(234, 88, 12, 0.8)',
    '900': 'rgba(234, 88, 12, 0.9)',
  },
  redAlpha: {
    '50': 'rgba(220, 38, 38, 0.05)',
    '100': 'rgba(220, 38, 38, 0.1)',
    '200': 'rgba(220, 38, 38, 0.2)',
    '300': 'rgba(220, 38, 38, 0.3)',
    '400': 'rgba(220, 38, 38, 0.4)',
    '500': 'rgba(220, 38, 38, 0.5)',
    '600': 'rgba(220, 38, 38, 0.6)',
    '700': 'rgba(220, 38, 38, 0.7)',
    '800': 'rgba(220, 38, 38, 0.8)',
    '900': 'rgba(220, 38, 38, 0.9)',
  },
  yellowAlpha: {
    50: 'rgba(223, 254, 0, 0.05)',
    100: 'rgba(223, 254, 0, 0.1)',
    200: 'rgba(223, 254, 0, 0.2)',
    300: 'rgba(223, 254, 0, 0.3)',
    400: 'rgba(223, 254, 0, 0.4)',
    500: 'rgba(223, 254, 0, 0.5)',
    600: 'rgba(223, 254, 0, 0.6)',
    700: 'rgba(223, 254, 0, 0.7)',
    800: 'rgba(223, 254, 0, 0.8)',
    900: 'rgba(223, 254, 0, 0.9)',
  },
};

export const getColors = (colorMode: 'light' | 'dark') => {
  const baseColors = colorMode === 'light' ? universalLight : universalDark;

  return {
    ...commonColors,
    ...baseColors,
  };
};

export { commonColors, universalDark, universalLight };
